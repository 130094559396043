<template>
	<div>
		<el-dialog :draggable="true" width="40%" @close="Reset" :destroy-on-close="true" v-model="outerVisible"
			:title="title">
			<div class="dialog">
				<el-form ref="ruleForm" label-width="80px">
					<el-form-item label="排序">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.orderby" placeholder="请输入排序"></el-input>
					</el-form-item>
					<el-form-item label="类型">
						<span class="xx">*</span>
						<el-select clearable v-model="type" @change="change" placeholder="请选择类型">
							<el-option label="列表" :value="0"></el-option>
							<el-option label="菜单" :value="1"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="名称">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.name" placeholder="请输入名称"></el-input>
					</el-form-item>
					<el-form-item label="路由名称" v-if="type==0">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.path" placeholder="请输入路由名称"></el-input>
					</el-form-item>
					<el-form-item label="路由路径" v-if="type==0">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.component" placeholder="请输入路由路径"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">取消</el-button>
					<el-button size="mini" class="dialog-btn el-white" type="primary" @click="SubmitForm">确定
					</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/menu";
	export default {
		data() {
			return {
				title: '',
				outerVisible: false,
				ruleForm: {
					name: '',
					pid: "",
					type: 0,
					icon: '',
					orderby: 0,
					src: '',
					component: '',
					path: '',
				},
				type: 0

			}
		},
		methods: {
			// 类型
			change() {
				this.ruleForm.path = ""
				this.ruleForm.component = ""
			},
			// 获取信息
			getuser() {
				this.$http.post(api.info, {
						id: this.ruleForm.id
					})
					.then((res) => {
						if (res.code == 200) {
							this.ruleForm = res.data
							if(!res.data.path) {
								this.type = 1
							}else {
								this.type = 0
							}
						}
					})
			},
			// 清空数据
			Reset() {
				for (let key in this.ruleForm) {
					this.ruleForm[key] = ''
				}
				this.ruleForm.pid = 0
				this.ruleForm.type = 0
				this.change()
				this.type = 0
			},
			//新增/编辑
			SubmitForm() {
				if (this.ruleForm.name === '') {
					ElMessage({
						message: '请输入名称',
						type: 'warning',
					})
				} else if (this.ruleForm.path === '' && this.type == 0) {
					ElMessage({
						message: '请输入路由名称',
						type: 'warning',
					})
				} else if (this.ruleForm.component === '' && this.type == 0) {
					ElMessage({
						message: '请输入路由路径',
						type: 'warning',
					})
				} else {
					if (this.title == '新增') {
						this.add()
					} else {
						this.edit()
					}

				}

			},
			// 编辑
			edit() {
				this.$http.post(api.edit, this.ruleForm)
					.then((res) => {
						if (res.code == 200) {
							ElMessage({
								type: 'success',
								message: '编辑成功',
							})
							this.outerVisible = false
							// 刷新列表
							this.$emit('SubmitForm')
						}
					})
			},
			// 新增
			add() {
				this.$http.post(api.add, this.ruleForm)
					.then((res) => {
						if (res.code == 200) {
							ElMessage({
								type: 'success',
								message: '新增成功',
							})
							this.outerVisible = false
							// 刷新列表
							this.$emit('SubmitForm')
						}
					})
			},
			// 显示隐藏
			show(val, pid, id) {
				this.ruleForm.pid = pid
				if (id) {
					this.ruleForm.id = id
					this.getuser()
				}
				this.title = val
				this.outerVisible = true
			}
		}
	}
</script>

<style scoped>
</style>